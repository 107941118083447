import { Routes as WrapperRoutes, Route, BrowserRouter } from 'react-router-dom'
import ComponentePrivado from '../middlewares/componentePrivado'
import PoliticaPrivacidade from '../pages/PoliticaPrivacidade'
import TermosDeUso from '../pages/TermosUso'
import Cobranca from '../pages/Cobranca'
import Contato from '../pages/Contato'
import Gesttor from '../pages/Gesttor'
import LinkPro from '../pages/LinkPro'
import Links from '../pages/Links'
import Home from '../pages/Home'
import PortalAuth from '../pages/PortalAuth'
import PortalCandidato from '../pages/PortalCandidato'
import PortalVagas from '../pages/PortalVagas/PortalVagas'
import ModalDetalhesVagas from '../components/ModalDetalhesVagas'

function Routes () {
    return (
        <BrowserRouter>
            <WrapperRoutes>
                <Route path='*' element = { <Home /> } />
                <Route path='/' element = { <Home /> } />
                <Route path='/contato' element = { <Contato />} />
                <Route path='/gesttor' element= {<Gesttor/>} />
                <Route path='/linkpro' element= {<LinkPro/>} />
                <Route path='/cobranca/:idCliente' element = { <Cobranca />} />
                <Route path='/politicaprivacidade' element = {<PoliticaPrivacidade />} />
                <Route path='/termosuso' element={<TermosDeUso />} />
                <Route path='/portal-auth' element={<PortalAuth/>}/>
                <Route path='/portal-candidato' element={<ComponentePrivado><PortalCandidato/></ComponentePrivado>}/>
                <Route path='/vagas' element={<PortalVagas/>}/>
                <Route path='/vagas/:idVaga' element={<ModalDetalhesVagas />}/>
                <Route path='/links' element={<Links/>}/>
            </WrapperRoutes>
        </BrowserRouter>
    )
}

export default Routes